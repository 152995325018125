import React from 'react';
import { motion } from 'framer-motion';
import { FaReact, FaCss3Alt, FaHtml5, FaJs } from 'react-icons/fa';
import { SiRedux, SiTailwindcss, SiNextdotjs, SiFramer, SiTypescript } from 'react-icons/si';

export default function TechIcons() {
  const floatingAnimation = {
    y: [0, -10, 0],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'easeInOut',
    },
  };

  const techStack = [
    { icon: <FaReact />, name: "React", color: "text-blue-500" },
    { icon: <SiTailwindcss />, name: "Tailwind CSS", color: "text-teal-500" },
    { icon: <SiNextdotjs />, name: "Next.js", color: "text-white" },
    { icon: <SiRedux />, name: "Redux", color: "text-purple-600" },
    { icon: <FaHtml5 />, name: "HTML", color: "text-orange-600" },
    { icon: <FaCss3Alt />, name: "CSS", color: "text-blue-600" },
    { icon: <FaJs />, name: "JavaScript", color: "text-yellow-500" },
    { icon: <SiFramer />, name: "Framer Motion", color: "text-pink-500" },
    { icon: <SiTypescript />, name: "TypeScript", color: "text-blue-700"},
  ];

  return (
    <div className="tech-icons-container  flex flex-wrap justify-center items-center gap-8 p-6">
      {techStack.map((tech, index) => (
        <motion.div
          key={index}
          className={`tech-icon relative flex flex-col items-center ${tech.color} text-6xl group`}
          animate={floatingAnimation}
        >
          {tech.icon}
          <span className="tech-icon-name absolute bottom-[-30px] text-xs text-white bg-black px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            {tech.name}
          </span>
        </motion.div>
      ))}
    </div>
  );
}
