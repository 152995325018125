import { useTypewriter } from "react-simple-typewriter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import TechIcons from "./TechIcons.jsx";
import ProfileImage from "../image/portfolio-black-removebg-preview.png";
import { faMapMarkerAlt, faSchool, faBook, faDumbbell } from "@fortawesome/free-solid-svg-icons";

export default function About() {
  const [text] = useTypewriter({
    words: ["Front-End Developer!", "Creative Coder!"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  return (
    <section
      id="about"
      className="relative h-fit overflow-hidden  gap-8 p-8 pt-32"
    >
      <div className="flex max-mdl:flex-col items-center justify-center gap-8 ">
      
      <img
        src={ProfileImage}
        alt="Tsiakiris Nikos"
        className="w-1/3 max-w-[300px] md:max-w-[400px] lg:max-w-[500px] object-contain"
      />

      <div className="w-full max-w-4xl flex flex-col items-start gap-6">
        <h1 className="text-2xl font-bold text-white">
          Hi, I'm Tsiakiris Nikos, <br /> a {text}
        </h1>

        <p className="text-md text-gray-200 leading-relaxed">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-200" />
          &nbsp; I come from the heroic city Naousa, in the prefecture of Imathia.
          <br />
          <br />
          <FontAwesomeIcon icon={faSchool} className="text-gray-200" />
          &nbsp; I graduated in 2022 with an MSc (Integrated master) in Information and Electronic Engineering
          from the International Hellenic University, Thessaloniki (Sindos campus).
          <br />
          <br />
          <FontAwesomeIcon icon={faBook} className="text-gray-200" />
          &nbsp; My technical stack includes JavaScript, Java, and SQL. I have experience with React and Next.js,
          along with knowledge of backend databases like MongoDB. I enjoy creating seamless user interfaces,
          focusing on performance and accessibility.
          <br />
          <br />
          <FontAwesomeIcon icon={faDumbbell} />
          &nbsp; My hobbies include sports such as basketball and MMA, and I have a passion for working out at the gym.
          These activities help me stay active and maintain a balanced lifestyle, which fuels my energy and focus in my professional work.
        </p>

        <div className="mt-4 flex gap-x-4 items-center">
          <a
            href="https://github.com/ntsiakiris"
            className="text-gray-200 hover:text-teal-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </a>
          <a
            href="https://www.linkedin.com/in/nikolaos-tsiakiris-b047a2229/"
            className="text-gray-200 hover:text-teal-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
      </div>
      </div>

      
      <div className="mt-12 flex justify-center w-full">
        <TechIcons />
      </div>
    </section>
  );
}
