import  { useState } from "react";
import TicTacToe from "./TicTacToe";
import Calculator from "./Calculator";
import RockPaperScissors from "./RockPaperScissors";
import BackButton from "./BackButton";
// import BlockBreaker from "./BlockBreaker";
import { FaInstagram, FaFacebook, FaLinkedin, FaGithub } from "react-icons/fa";

export default function Phone() {
    const [currentApp, setCurrentApp] = useState(null);
    const [currentUrl, setCurrentUrl] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const openInNewTab = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div className="flex justify-center items-center">
            <div className="mockup-phone">
                <div className="camera"></div>
                <div className="display bg-gray-100 relative">
                    {currentApp ? (
                        <div className="artboard artboard-demo phone-1 flex flex-col items-center justify-center relative">
                            <BackButton
                                onClick={() => {
                                    setCurrentApp(null);
                                    setCurrentUrl("");
                                    setErrorMessage("");
                                }}
                                className="bg-red-500 hover:bg-red-700"
                                label="Go Back"
                            />
                            {currentApp === "TicTacToe" && <TicTacToe />}
                            {/* {currentApp === "TicTacToe" && <BlockBreaker />} */}
                            {currentApp === "Calculator" && <Calculator />}
                            {currentApp === "RockPaperScissors" && <RockPaperScissors />}
                            {currentApp === "WebView" && (
                                <div className="w-full h-full flex flex-col justify-center items-center">
                                    {errorMessage ? (
                                        <p className="text-red-500">{errorMessage}</p>
                                    ) : (
                                        <iframe
                                            src={currentUrl}
                                            title="Web Page"
                                            className="w-full h-full rounded-md"
                                            onError={() =>
                                                setErrorMessage("Cannot display the webpage inside the app. Please open in a new tab.")
                                            }
                                            frameBorder="0"
                                        ></iframe>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="artboard artboard-demo phone-1 grid grid-cols-3 gap-2 p-2">
                            {/* Tic-Tac-Toe Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => setCurrentApp("TicTacToe")}
                                    className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-700 transition duration-300"
                                >
                                    🎮
                                </button>
                                <p className="text-xs mt-1">Tic-Tac-Toe</p>
                            </div>
                            {/* Block Breaker Icon */}
                        {/* <div className="app-icon flex flex-col items-center">
                            <button
                                onClick={() => setCurrentApp("BlockBreaker")}
                                className="bg-green-500 text-white p-2 rounded-full hover:bg-green-700 transition duration-300"
                            >
                                ⬛
                            </button>
                            <p className="text-xs mt-1">Block Breaker</p>
                        </div> */}

                            {/* Calculator Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => setCurrentApp("Calculator")}
                                    className="bg-purple-500 text-white p-2 rounded-full hover:bg-purple-700 transition duration-300"
                                >
                                    🔢
                                </button>
                                <p className="text-xs mt-1">Calculator</p>
                            </div>

                            {/* Rock-Paper-Scissors Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => setCurrentApp("RockPaperScissors")}
                                    className="bg-red-500 text-white p-2 rounded-full hover:bg-red-700 transition duration-300"
                                >
                                    ✊
                                </button>
                                <p className="text-xs mt-1">RPS</p>
                            </div>

                            {/* Instagram Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => openInNewTab("https://www.instagram.com/tsak.__/")}
                                    className="bg-pink-500 text-white p-2 rounded-full hover:bg-pink-700 transition duration-300"
                                >
                                    <FaInstagram size={24} />
                                </button>
                                <p className="text-xs mt-1">Instagram</p>
                            </div>

                            {/* Facebook Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => openInNewTab("https://www.facebook.com/nicktsiakiris/")}
                                    className="bg-blue-700 text-white p-2 rounded-full hover:bg-blue-900 transition duration-300"
                                >
                                    <FaFacebook size={24} />
                                </button>
                                <p className="text-xs mt-1">Facebook</p>
                            </div>

                            {/* LinkedIn Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => openInNewTab("https://www.linkedin.com/in/nikolaos-tsiakiris")}
                                    className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-700 transition duration-300"
                                >
                                    <FaLinkedin size={24} />
                                </button>
                                <p className="text-xs mt-1">LinkedIn</p>
                            </div>

                            {/* GitHub Icon */}
                            <div className="app-icon flex flex-col items-center">
                                <button
                                    onClick={() => openInNewTab("https://github.com/ntsiakiris")}
                                    className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-900 transition duration-300"
                                >
                                    <FaGithub size={24} />
                                </button>
                                <p className="text-xs mt-1">GitHub</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
