import React from "react";
import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import TimeLine from "./components/TimeLine.jsx";
import Resume from "./components/Resume/Resume.jsx";
import Preloader from "./components/Preloader.jsx";
import Background from "./components/utils/Background.jsx";
import Welcome from "./components/Welcome.jsx";


function App() {
  return (
    <div className="App">
      <Background />
      <Preloader />
      <Header />
      <Welcome />
      <About />
      <Resume />
      <TimeLine />
      <Contact />
      <Footer />
      
    </div>
  );
}

export default App;